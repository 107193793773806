.navbar-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 5;
}

@keyframes fadeout{
    0% { top:0; }
    12% {top:48px }
    88% { top:48px; }
    100% { top:0px; }
}

.notification
{
    position:fixed;
    top:48px;
    width:375px;
    z-index:4;
    padding:8px 15px;
    font-size: 13px;
    line-height: 16px;
    animation: 3.3s ease 0s normal forwards 1 fadeout;

    &.success
    {
        background-color: #D8F5D9;
        color: #127B16;
        box-shadow: inset 0 -0.5px 0 0 #8EEE92;
    }

    &.error
    {
        background-color: #FFF1F1;
        box-shadow: inset 0 -0.5px 0 0 rgba(237,41,41,0.3);
        color: #ED2929;
    }

    &.info
    {
        background-color: #DBF3FA;
        box-shadow: inset 0 -0.5px 0 0 #90D9F1;
        color: #045772;
    }
}

.search-button {
    cursor: pointer;
    border-radius: 3px;
    width: 32px;
    height: 32px;
    text-align: center;
    padding-top: 3px;
    position:relative;
    left:6px;

    @media (hover:hover) {
        &:hover {
            background: rgba(255,255,255,0.3);
        }
    }

    &:active {
        background-color: rgba(255,255,255,0.1);
    }

}

.navbar-light .navbar-nav .nav-link {
    color: #0E0E0E !important;
}


.header {
    max-width: 375px;
    margin: 0 auto;
    
    &.navbar {
        background-color: #20B4E4;
        height: 48px;
        padding-top: 5px;
    }

    .navbar-brand {
        padding: 0;
        margin-left: 35px;
        margin-top:-1px;
    }

    .dropdown-menu {
        border: 0;
        border-radius: 0;
        width: calc(100% - 55px);
        margin: 0;
        background: #fafafa;

        .dropdown-item {
            font-size: 15px;
            line-height: 20px;

            &:active {
                background-color: #cccccc;
            }
        }
    }
    
    .dropdown-toggle {
        font-weight: bold;

        &:after {
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='13' height='8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.99.894h.212l.707.707v.212l-5.9 5.9h-.216l-5.9-5.9v-.212l.708-.707h.212l5.089 5.088z' fill='%23878787' fill-rule='evenodd'/%3E%3C/svg%3E");
            border: 0;
            width: 13px;
            height: 8px;;
            float: right;
            margin-right: 5px;
            margin-top: 5px;
        }


        &:hover {
            &:after {
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='13' height='8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.99.894h.212l.707.707v.212l-5.9 5.9h-.216l-5.9-5.9v-.212l.708-.707h.212l5.089 5.088z' fill='%234c4c4c' fill-rule='evenodd'/%3E%3C/svg%3E");
            }
        }

        &:active {
            &:after {
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='13' height='8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.99.894h.212l.707.707v.212l-5.9 5.9h-.216l-5.9-5.9v-.212l.708-.707h.212l5.089 5.088z' fill='%23191919' fill-rule='evenodd'/%3E%3C/svg%3E");
            }
        }
    }

    .show > .dropdown-toggle::after {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='13' height='8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.99 7.713h.212l.707-.707v-.213l-5.9-5.9h-.216l-5.9 5.9v.213l.708.707h.212l5.089-5.089z' fill='%23878787' fill-rule='evenodd'/%3E%3C/svg%3E");
    }

    .navbar-collapse {
        z-index: 5;
        margin-top: 10px;
        position: absolute;
        top: 38px;
        left: 0;
        width: 100%;
        
        .nav-bg {
            background-color: rgba(0,0,0,0.8);
            height: 100%;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            position: absolute;
        }

        .header-overlay {
            top: -48px;
            height: 48px;
            left: 0;
            right: 0;
            width: 100%;
            position: absolute;
        }

        .navbar-nav {
            height: 1000px;
            
          .nav-link:first-child {
              margin-top: 4px;

              &:before {
                  content: '';
                  background:  #ffffff;
                  width: 100%;
                  height: 4px;
                  position: absolute;
                  left: 0;
                  top: -4px;
              }
          }
        }

        .nav-link {
            background-color: #ffffff;
            width: calc(100% - 55px);
            padding: 11px 12px;
            font-size: 15px;
            color: #0E0E0E;
            position: relative;
/*
            &:not(.dropdown-toggle) {
                &:after {
                    content: '';
                    width: 8px;
                    height: 13px;
                    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='8' height='13' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.894 11.99v.212l.707.707h.212l5.9-5.9v-.216l-5.9-5.9h-.212l-.707.708v.212l5.088 5.089L.894 11.99zm6.819-5.192l-.002-.002-.107.106.107.106.002-.002v-.208zm-.215.205l.106-.101-.103-.104-.003.205z' fill='%230E0E0E' fill-rule='evenodd'/%3E%3C/svg%3E");
                    float: right;
                    margin-right: 10px;
                }
            }
*/
            &:hover {
                color: #137B9D !important;
            }

            &:active {
                color: #045772 !important;
            }
        }
    }

    .navbar-toggler {
        border: 0;
        font-size: inherit;
        padding: 7px 5.85px 7px 4.85px;
        left: 9.15px;
        position: absolute;
        width: 32px;
        height: 32px;
        margin-top:  1px;
        
        @media (hover:hover) {
            &:hover {
                background: rgba(255,255,255,0.3);
            }
        }

        &:active {
            background-color: rgba(255,255,255,0.1);
        }

        &:focus,
        &:active {
            outline: 0 !important;
        }

        .navbar-toggler-icon {
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='22' height='15' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.85 1L1 .85h20l.15.15v1l-.15.15H1L.85 2V1zm0 12l.15-.15h20l.15.15v1l-.15.15H1L.85 14v-1zm0-6L1 6.85h20l.15.15v1l-.15.15H1L.85 8V7z' fill='%23ffffff' fill-rule='evenodd'/%3E%3C/svg%3E") !important;
            background-size: cover;

            width: 22px;
            height: 15px;
        }

        &.collapsed {
            .navbar-toggler-icon {
                background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='16' height='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.92 8.108l-.098.096-.107-.106.107-.106L16 15.082v.212l-.715.706h-.215L8 9.017.93 16H.715L0 15.294v-.212l7.178-7.09.107.106-.107.106-.098-.096v-.216l.098-.096.107.106-.107.106L0 .918V.706L.715 0H.93L8 6.983 15.07 0h.215L16 .706v.212l-7.178 7.09-.107-.106.107-.106.098.096z' fill='%23ffffff' fill-rule='evenodd'/%3E%3C/svg%3E") !important;
                width: 14px;
                height: 14px;
            }
        }
    }


    
    > span {
        padding-left:0 !important;
        padding-right:0 !important;
        vertical-align:top;
        line-height:19.25px;
        max-height:19.25px;
        font-size:19.25px;
        color:#FFF;
    }
  
}

.search-bar {
    background: #20B4E4;
    top: 0;
    left: 0;
    right: 0;
    position: absolute;
    height: 48px;
    width: 100%;
    padding: 8px;

    input {
        //max-width: 288px;
        width: calc(100% - 71px);
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='22' height='22' xmlns='http://www.w3.org/2000/svg'%3E%3Cg transform='translate(-5 -5)' fill='none' fill-rule='evenodd'%3E%3Crect fill='none' width='32' height='32' rx='3'/%3E%3Cpath d='M14 22.15a8.15 8.15 0 1 1 6.204-2.865l5.902 5.902v.213l-.706.706h-.213l-5.902-5.902A8.117 8.117 0 0 1 14 22.15zm0-1.3a6.85 6.85 0 1 0 0-13.7 6.85 6.85 0 0 0 0 13.7z' fill='%23707070'/%3E%3C/g%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: 6px center;
        background-size: 16px 16px;
        border: 1px solid transparent;
        padding: 3px 0 5px 30px;
        border-radius: 0;
        
        &::placeholder {
            color: rgba(14,14,14,0.3);
            font-size: 15px;	
            line-height: 20px;
            padding-top: 3px;
        }

        &:active,
        &:focus {
            outline: 0;
            border: 1px solid #137B9D;
            background-position: 7px center;

            &::placeholder {
                color: white !important;
                opacity: 0;
            }
          
        }
    }

    button {
       color: #fff;
       line-height: 20px;
       font-size: 15px;
       background: transparent;
       border: 0;
       margin-left: 16px;
       padding: 0;
       top: 1px;
       position: relative;

       &:hover {
        opacity: 0.75;
       }

       &:focus,
       &:active {
        opacity: 1;
        outline: 0 !important;
       }
    }
}

.search-container {
    max-width: 375px;
    width: 375px !important;
    margin: 0 auto;
    padding: 0;
    margin-left: -16px;
}

.search-box {
    background: #fff;
    width: 100%;
    left: 0;
    top: 48px;
    bottom: 0;
    padding: 15px 0 150px 0; 
    overflow-y: scroll;
    overflow-x: hidden;
    
    a {
        text-decoration: none !important;
    }

    .title {
        border-bottom: 0.5px solid rgba(14,14,14,0.1);
        margin: 15px 15px 0 15px;
        padding-bottom: 7px;
        line-height: 24px;
        
        span {
            font-weight: 600;
            color: #0E0E0E;
            font-size: 17px;
        }

        a {
            color: #20B4E4;
            font-size: 15px;
            float: right;
            display: none;
        }

    }

    .recently-container {
        overflow-y: hidden;
        overflow-x: scroll;
        width: 375px;
    }
    
    ul {
        list-style: none;
        padding-left: 0;
        margin-top: 12px;
        
        &.popular, &.trending {
            margin-top: 5px;
        }

        &.auto-suggest {
            margin-top: 3px;
        }

        li {
            a {
                color: #0E0E0E;	
                font-size: 15px;	
                line-height: 36px;
                display: inline-block;
                width: 100%;
                padding: 0 15px;

                &:active,
                &:focus {
                    background-color: rgba(14, 14, 14, 0.1);
                    text-decoration: none;
                }
            }
        }

        &.recently {
            overflow-x: scroll;
            overflow-y: hidden;
            width: 540px;
            height: 100px;

            .product {
                width: 100px;
                max-width: 100%;
                height: 100px;
                float: left;
                padding: 0 0px 0 0;
                overflow:hidden;
                margin-left: 10px;
                
                &:first-child {
                    margin-left: 15px;
                }

                .bookmark,
                .prices {
                    display: none;
                }
            }
        }

        &.categories {
            padding-left: 15px;
            padding-right: 15px;

            li {
                border: 1px solid rgba(14,14,14,0.1);
                border-radius:2px;
                width:calc(50% - 22.5px);
                margin: 0px 15px 16px 15px;
                height:100%;
                
                &:nth-child(2n) {
                    margin-left: 0;
                }

                a { 
                    color: #0E0E0E;
                    font-size: 15px;
                    font-weight: 600;
                    padding:0;
                    line-height: 18px;
               

                    &:hover {
                        text-decoration: none;
                    }
                }
                
                span {
                    padding: 9px 10px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center!important;
                    width:100%;
                    min-height: 36px!important;
                    height: 100%;
                    

                }

                div {
                    width: 165px;
                    height: 104px;

                    img {
                        object-fit: cover;
                        width:calc(100% - 2px);
                        height: 104px;
                    }
                }
            }
        }
    }
}