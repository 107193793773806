body.overlay-open {
    overflow: hidden;
    height: 100%;
    max-width: 100%;
    overscroll-behavior: none;

    #root {
        overflow-y: scroll;
        overscroll-behavior: none;
    }

    @media (max-width: 1024px) {
        position: fixed;
        width: 100%;
    }
}


.overlay {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 6;
    bottom: 0;



    .xycenter {
        position: absolute;
        top: 300px;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #fff;
        width: calc(100% - 30px);
        max-width: 345px;
        padding: 32px 30px;
        box-shadow: 0 1px 3px 0 rgba(14, 14, 14, 0.15), 0 0 16px 0 rgba(14, 14, 14, 0.1), 0 1px 8px 0 rgba(14, 14, 14, 0.1);
        border-radius: 6px;
        min-height:428px;

        a {
            text-decoration: none;
            color:#20b4e4;

            &:hover {
                color: #137B9D;
            }

            &:active {
                color: #045772;
            }
        }

        h1 {
            color: #0E0E0E;
            font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
            font-size: 20px;
            line-height: 24px;
            text-align: center;
            margin-bottom: 8px;
            font-weight: normal;
        }

        .prompt {
            color: rgba(14, 14, 14, 0.5);
            font-size: 15px;
            line-height: 20px;
            text-align: center;
            margin-bottom: 0;
        }


        .center {
            text-align: center;
            margin: 0;
        }

        .btn
            {
                margin-top:8px;
            }




        .forgot {
            text-decoration: none;
            color: #20B4E4;
            font-size: 15px;
            line-height: 20px;
            text-align: center;
            margin-top: 24px;
            display: inline-block;

            &:hover {
                color: #20B4E4;
            }

            &:active {
                color: #137B9D;
            }

        }

        .signup {
            color: #20B4E4;
            font-size: 15px;
            font-weight: 600;
            line-height: 20px;
        }

        .grey
        {
            color: rgba(14, 14, 14, 0.5);
            line-height:20px;
        }

        .forgotLink
        {
            margin:32px 0 40px 0;
            a
            {
                color: rgba(14, 14, 14, 0.5);
                &:hover
                {
                    color:#0e0e0e;
                }
            }
        }

        .terms
        {
            margin:32px 0 40px 0;
            a
            {
                color: rgba(74,74,74,1);

                &:hover
                {
                    color:#0e0e0e;
                }
            }
        }

        .form-group {

            position:relative;
            margin-bottom:0px;

            input.form-control {

                :-webkit-autofill {
                    border: 0;
                    background: transparent;
                    border-bottom: 0.5px solid rgba(14, 14, 14, 0.1);
                }

                border: 1px solid #E7E7E7;
                box-sizing: border-box;
                border-radius: 3px;

                &:focus, &:active
                {
                    border-color:#B4B4B4;
                }
                
            }

            

            &.signup {

                &.email {
                    margin: 24px 0 8px 0;
                }

                

               
            }

            &.forgotEmail {
                    margin: 24px 0 0px 0;
            }

            &.signin {
                margin-top: 52px;
                margin-bottom: 4px;
            }

        }

        .form-control {
            font-size: 15px;
            line-height: inherit;
            padding:12px 10px;
            height:48px;
            
        
            &.is-invalid {
                color: #ED2929;
                background-image: none;
            }
        }

        .invalid-feedback {
            color: #ED2929;
            background:#FFF;
            font-size: 12px;
            line-height: 14px;
            font-weight:normal;
            padding: 0 5px;
            bottom:-5px;
            position:absolute;
            display: inline-block !important;
            width:auto;
        }

        input {
            border: 0;

            &:active,
            &:focus {
                outline: 0;
                box-shadow: none;
            }

            &::placeholder {
                font-size: 15px;
                line-height: 20px;
                color: rgba(14, 14, 14, 0.3);
                position:relative;
            }
        }

        .close {
            position:absolute;
            top:-46px;
            right:0px;
            color:rgba(255,255,255,0.8);
            font-size:15px;
            line-height:20px;
            font-weight:normal;
            opacity:1;
            text-shadow:none;
            outline:0;
            border-radius: 73px;
            border: 1px solid rgba(255,255,255,0.8);

            width: 64px;
            height: 32px;

            @media only screen and (min-device-width: 768px) {
                padding-bottom: 2px;
            }

            &:hover {
                color: rgba(255,255,255,1);
                border: 1px solid rgba(255,255,255,1);
                opacity: 1 !important;
            }

            &:focus,
            &:active {
                color:rgba(255,255,255,0.8);
                border: 1px solid rgba(255,255,255,0.8);
                opacity: 1;
                
                @media(max-width: 768px) {
                    &:focus,
                    &:active {
                        opacity: 0.65 !important;
                    }
                }
            }
        }



        .new-password {
            .prompt {
                margin-bottom: 52px;
            }

            .form-control {
                border-bottom: 0.5px solid rgba(14, 14, 14, 0.1);
            }

            .form-group {
                position: relative;
                margin-bottom: 4px;
            }

           
        }

        .forgotten
        {

            .cancelLink
            {
                a
                {
                    color: rgba(14, 14, 14, 0.5);
                    &:hover
                    {
                        color:#0e0e0e;
                    }
                }
            }
        }

        .forgottenPassword
        {
            padding:64px 0 87px 0;
            .prompt
            {
                display:block;
            }
        }

        .ispinner
        {
            width:18px;
            height:18px;
            margin-top:1px;
        }
    }
}