.products {
    overflow: hidden;
}

.products .bookmark {
    width: 24px;
    float: right;
    cursor: pointer;

    @media (max-width: 768px) {
        width: 32px;
        height: 32px;
        left: 12px;
    }

}

.product-container .options .bookmark
{
    width:32px;
    height:32px;
}

.product-container .related .bookmark
{
    padding:0px;
    left:13px;
}