#root {
    overscroll-behavior-y: contain;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
  }
  
body
{
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    overflow-y:scroll;
    overscroll-behavior-y: contain;

    &.hidden
    {
        height:100%;
        overflow:hidden;
    }

}

.app {
    max-width: 375px;
    margin:0 auto;
}

#launcher {
    bottom: 90px !important;
    margin-right: 15px;
}

a.blue
{
    text-decoration: none;
            color: #20B4E4;
            font-size: 15px;	
            line-height: 20px;

            display: inline-block;

            &:hover {
                color: #3081CC;
                text-decoration: none;
            }

            &:active {
                color: #3CA0FF;
                text-decoration: none;
            }
}

.app
{


.btn-primary {
    box-shadow: none !important;
    &:active {
        background-color: #045772 !important;
    }

    
}

.btn {
    margin-top: 20px;
    width: 100%;
    background-color: #20B4E4;
    height: 48px;
    font-size: 15px;
    border: 0;
    cursor: pointer;
    font-weight: 600;

    &:disabled {
        background:#6DD8FB;
        pointer-events: none;

        &:hover, &:active {
            background:#6DD8FB;
            top: 0 !important;
        }
    }

    &:not(:disabled):not(.loading) {

    &:active,
    &:active:focus {
        box-shadow: none;
    }
    }

    &:not(:disabled):not(.loading) {
    &:hover {
        background-color: #0EA8E0;
    }
    }

    &:not(:disabled):not(.loading) {
        &:active {
            background-color: #009AD5 !important;
        }
    }

    &.loading {
        &:hover {
        background-color: #20B4E4 !important;
        }
    }

    .spinner {
        margin-top: 0;
        background: linear-gradient(to bottom, #fff 10%, rgba(255, 255, 255, 0) 100%);

        &:before {
            background: linear-gradient(to right, rgba(255, 255, 255, 0) 50%, #fff 50%);
        }
        &:after {
            background: #20B4E4;
        }
    }
}
}