.search-box {
    background: #FFFFFF;
    width: 100%;
    margin-top: 9px;
}

.auto-suggest li
{
    span
    {
        opacity:.3;
    }
}