.footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 84px;
    z-index: 1;

    ul {
        height: 84px;

        list-style: none;
        margin: 0 auto;
        width: 375px;
        background-color: #FAFAFA;
        box-shadow: inset 0 1px 0 0 rgba(14,14,14,0.1);
        padding: 12px 0 0 0;

        li {
            text-align: center;

            a, button {
                text-align: center;
            display: inline-block;
            width: 20%;

                float: left;
                border:0;
                background:none;
            }
        }
    }
}
.BeaconContainer{
    right: 80px !important;
}

.BeaconFabButtonFrame
{
    display:none;
    bottom:110px !important;
    right:10px !important;
}