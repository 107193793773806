.overlay { 
  &.image {

    .xycenter {
        background: transparent;
        padding: 0;
        top: 50%;
        min-height: 0;

        img {
            width: 100%;
            border-radius: 6px;
        }
    }

  }

  &.share {
    .xycenter {
        background: #fff;
        padding-left: 27px;
        bottom: 40px !important;
        top: auto !important;
        transform: translate(-50%, 0);
        min-height: 0;
        border-radius: 6px;

        ul {
          list-style: none;
          margin: 0;
          padding: 0;

          li {
            margin: 0;
            padding: 0;
            
            a {
                color: rgba(14, 14, 14, 0.75);
                line-height: 20px;
                display: inline-block;
                margin-bottom: 23px;
                width: 100%;

                svg {
                    margin-right: 13px;
                    position: relative;
                    top: -1px;
                }

                &.twitter {
                    svg {
                        margin-right: 10px;
                    }
                }

             
            }

            button.shareMore,
            button.copy {
                display: inline-block;
                padding: 0;
                margin-bottom: 23px;
                color: rgba(14, 14, 14, 0.75);
                border: 0;
                background: transparent;

                svg {
                    margin-right: 16px;
                }

                &:hover, &:focus, &:active {
                    outline: 0;
                }
            }

            button.shareMore {

                svg {
                    position: relative;
                    top: -1px;
                }
            }

            &:last-child {
                
                button {
                    margin-bottom: 0 !important;
                }
            }
          }
      }
    }
  }
}

.variant.selected {
    .overlayThumbnail {

        &:before {
            content: '';
            position: absolute;
            border: 3px solid #20B4E4;
            border-radius: 3px;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
        }
    }
}

.overlayThumbnail {
    border: 0;
    background: transparent;
    padding:  0;
    position: relative;
    height: 100%;

    &:not(.noPhoto) {

        &:after {
            content: '';
            background-image: url("data:image/svg+xml,%3Csvg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 2C1 1.44772 1.44772 1 2 1H23C24.1046 1 25 1.89543 25 3V25H4C2.34315 25 1 23.6569 1 22V2Z' fill='%2320B4E4'/%3E%3Cpath d='M15.5 15.5L20 20' stroke='white' stroke-width='1.3' stroke-linecap='round'/%3E%3Ccircle cx='11.5' cy='11.5' r='5.35' stroke='white' stroke-width='1.3'/%3E%3Cpath d='M1 2C1 1.44772 1.44772 1 2 1H23C24.1046 1 25 1.89543 25 3V25H4C2.34315 25 1 23.6569 1 22V2Z' stroke='%2320B4E4'/%3E%3C/svg%3E%0A");
            background-color: #20B4E4;
            background-position: 0 -2px;
            border-radius: 0 0 0 3px;
            width: 24px;
            height: 24px;
            position: absolute;
            right: 2px;
            top: 2px;
            z-index: 2;
        }
    }

    img {
        width: 85px;
        height: 85px;
        float: left;
        position: relative;
        object-fit: cover;
    }
}