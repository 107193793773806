.list {
    padding: 16px 15px;
    margin-bottom: 64px;
    margin-top: 48px;


    &.home
    {
        margin-top: 0px;
    }

    &.bookmarks-list {
        &.empty
        {
            margin-top: 28px;
        }
        
    }
    
    .products
    {
        width:100%;
    }

    >.row {
        .product {
            float: left;
            max-width:calc(50% - 15px);

            &:nth-child(2n+1) {
                padding-right: 7.5px;
                clear: both;
                margin-left:15px;
                padding-left:0px;
                
            }

            &:nth-child(2n+2) {
                padding-left: 7.5px;
                margin-right:15px;
                padding-right:0px;
            }
        }

        .products {
            .product:last-child {
                margin-bottom: 32px;
            }
        }
    }

    .breadcrumb {
        background: none;
        text-align: center;
        color: #0E0E0E;
        font-size: 17px;
        font-weight: 600;
        line-height: 24px;
        padding: 0;
        margin-bottom: 12px;

        a {
            color: #0E0E0E;

            &:hover {
                text-decoration: none;
            }

            &.back {
                background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='10' height='17' xmlns='http://www.w3.org/2000/svg'%3E%3Cg transform='translate(-10 -7)' fill='none' fill-rule='evenodd'%3E%3Crect fill='none' width='32' height='32' rx='3'/%3E%3Cpath d='M19.713 22.99v.212l-.707.707h-.213l-7.9-7.9v-.216l7.9-7.9h.213l.707.708v.212l-7.089 7.089 7.089 7.088zm-8.82-7.192l.003-.002.106.106-.106.106-.002-.002v-.208zm.215.205l-.106-.101.104-.104.002.205z' fill='rgb(14,14,14)'/%3E%3C/g%3E%3C/svg%3E");
                width: 10px;
                height: 17px;
                display: inline-block;
                position: absolute;
                opacity: 0.5;
                margin-left: -5px;
                margin-top: 3px;

                &:hover {
                    opacity: 0.75;
                }

                &:active {
                    opacity: 0.75;
                }
            }

            &.category {
                width: 100%;
                text-align: center;
            }
        }
    }
}

.product {
    margin-bottom: 32px;
   // overflow: hidden;
    // height: 194px;

   a {

    .img {
        &:after {
            transition: all 0s;
        }
    }

    &.clicked {
        top:  1px;
    
        .img {
            &:after {
                background-color: rgba(14, 14, 14, 0.3);
               // transition-delay: 0.5s;
            }
        }
    }

    &:active {
      

        .img {
            &:after {
           //     background-color: rgba(14, 14, 14, 0.1);
            }
        }

        .price {
           // color: #20B4E4;
        }

    }
/*
    &:not(.disabled) {
        position: relative;
        
        &:active {
            top:  1px;
    
            .img {
                &:after {
                    background-color: rgba(14, 14, 14, 0.3);
                   // transition-delay: 0.5s;
                }
            }

            .price {
            //    color: #137B9D;
            }

        }
    }*/
}

    .img {
        height: 165px;
        width: 165px;
        overflow:hidden;
        position: relative;

        img
        {
            height:100%;
            object-fit:cover;
        }

        &:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            //    z-index: 1;
        }

    }

    .discount {
        position: absolute;
        z-index: 1;
        top: 0;
        //   left: 15px;
        color: #fff;
        background-color: #000000;
        width: 42px;
        height: 21px;
        text-align: center;
        font-size: 12px;
        font-weight: bold;
        padding-top: 2px;
        letter-spacing: -0.5px;
    }

    .price {
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        color: #0E0E0E;

        .currency {
            font-weight: 400;
        }
    }

    .bought {
        color: #B4B4B4;
        font-size: 12px;
        line-height: 14px;
        display: inline-block;
        position: relative;
        top: -5px;
    }

    .stroke {
        color: #B4B4B4;
        text-decoration: line-through;
        margin-left: 5px;
        font-size: 15px;
    }

    .prices {
        width: calc(100% - 24px);

        @media (max-width: 768px) {
            width: calc(100% - 32px);

        }
    
        float: left;
        line-height: 20px;
        margin-top: 6px;
    }

    .bookmark {
        margin-top: 4px;
        position: relative;
        left: 4px;
        margin-bottom: 1px;

        svg {
            width: 24px;
            height: 24px;
        }
    }


}

.list + .popular
{
    margin-top:-64px;
    padding-bottom:100px;
}

.popular {
    &::after
    {
        display:table;
        content: "";
        clear:both;
    }
    h2 {
        color: #0E0E0E;
        font-size: 17px;
        font-weight: 600;
        line-height: 24px;
        padding-left: 15px;
        margin-bottom: 12px;
    }

    .products {
        .product {
            float: left;
            padding: 0;
            width: 123px;
            height: 123px;
            margin-bottom: 2px;

            object-fit: cover;

            &:nth-child(3n+1) {
                margin-right: 2px;
                padding-left: 0 !important;
            }

            &:nth-child(3n+2) {
                width: 125px;
                padding-left: 0 !important;

                img {
                    width: 125px;

                }
            }

            &:nth-child(3n+3) {
                margin-left: 2px;
                padding-left: 0 !important;

            }

            img {
                width: 123px;
                height: 123px;
                object-fit: cover;
            }
        }
    }
}

.test {
    display: block;
    width: 100%;
    height: 30px;
}