.ais-Hits
{

}

.ais-Hits-list
{
    list-style:none;
    padding:0;
    margin:30px 0;
    display:flex;
    flex-wrap: wrap;
}

.ais-Hits-item
{
    flex: 0 0 50%;
    max-width: 50%;
}