.new-products {

    margin-top: -12px;
    margin-bottom:0px;
    padding-bottom:0px;
    margin-bottom:16px;
    position:relative;

    &::after
    {
        position: absolute;
        width:98px;
        content: "";
        bottom:-1px;
        height:3px;
        background:#20B4E4;
        left: 139px;
    }

    &.first {
        margin-top:54px;
    }

    h2 {
        text-align: center;
        font-family: "Circular";
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;

        color: #082833;
        font-feature-settings: 'ss04'on, 'ss06'on, 'ss07'on;
        margin-bottom: 24px;
    }
}