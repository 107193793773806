.app {
    position: relative;
    font-size: 15px;
}

.black {
    height: 100%;

    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    z-index: 5;
    top: 0;
    left: 0;
    display: block;
    transition: all .3s ease-in-out;
    visibility: hidden;
    opacity: 0;


    &.visible {
        visibility: visible;
        opacity: 1;
    }
}

.error-note {
    background-color: #FFF1F1;
    border-top: solid 0.5px rgba(237, 41, 41, 0.3);
    padding: 8px 0;
    text-align: center;
    margin: 0 auto;
    width: 375px;
    color: #ED2929;
    font-size: 13px;
    line-height: 16px;
}

.close-option {
    border: 0;
    box-shadow: none;
    position: absolute;
    background: none;
    color: #20B4E4;
    top: 24px;
    left: 9px;
    font-size: 17px;
    padding-left: 5px;

    &:active,
    &:focus {
        outline: 0;
    }
}

.image-options {
    right: 0;
    position: absolute;
    background-color: rgba(14, 14, 14, 0.3);
    color: white;
    font-size: 10px;
    font-weight: 600;
    line-height: 14px;
    display: inline-block;
    height: 32px;
    padding: 9px 8px;
    z-index: 1;
    top: 2px;
}

.slick-slide>div>div {
    position: relative;
    height: 350px;
}

.slick-slide img {
    object-fit: cover;
    height: 100%;
}



.product-container {
    margin-top: 46px;
    padding-bottom: 100px;
    position: relative;

    .discount {
        z-index: 1;
        top: 2px;
        background-color: #0E0E0E;
        color: #fff;
        padding: 4px 5px;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: -0.5px;
        line-height: 24px;
        min-width: 41px;
        position: absolute;
    }

    >div:not(.spinner) {
        padding-bottom: 40px;
    }

    .slick-arrow {
        background-color: rgba(14, 14, 14, 0.3);
        height: 48px;
        width: 32px;
        z-index: 1;

        &:after {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='10' height='17' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.713 15.99v.212l-.707.707h-.213l-7.9-7.9v-.216l7.9-7.9h.213l.707.708v.212L2.624 8.902l7.089 7.088zM.893 8.798l.003-.002.106.106-.106.106-.002-.002v-.208zm.215.205l-.106-.101.104-.104.002.205z' fill='%23ffffff' fill-rule='evenodd'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: 10px center;
        }

        &:before {
            display: none;
        }

        &:hover {
            background-color: rgba(14, 14, 14, 0.3);
        }

        @media (hover:hover) {
            &:hover {
                background-color: rgba(14, 14, 14, 0.6);
            }

            &:focus,
            &:active {
                background-color: rgba(14, 14, 14, 0.8);
            }
        }

    }

    .slick-arrow.slick-prev {
        left: 0px;


    }

    .slick-arrow.slick-next {
        right: 0px;

        &:after {
            -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
        }

    }

    .thick-hr {
        background-color: #FAFAFA;
        width: 375px;
        height: 5px;
        margin: 24px 0 9px 0;

        &.last {
            margin-top: 0;
            margin-bottom: 12px;
        }

        &.before-products {
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    .bookmark {
        float: right;
        padding: 0px 0 0 5px;
        position: relative;
        cursor: pointer;
        margin-top: 4px;
        position: relative;
        left: 4px;
        margin-bottom: 1px;

        div {
            width: 32px;
            height: 32px;
        }

        svg {
            vertical-align: top;
        }
    }

    h1 {
        color: #0E0E0E;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 16px;
        margin-top: 10px;
        font-weight: normal;
        padding-right: 0px;
        width: calc(100% - 95px);
    }

    h2 {
        color: #0E0E0E;
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        margin-bottom: 2.5px;
    }

    select {
        position: relative;
        width: 100%;
        padding-left: 0;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-image: url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' encoding='UTF-8'?%3E%3Csvg width='13px' height='8px' viewBox='0 0 13 8' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: sketchtool 54.1 (76490) - https://sketchapp.com --%3E%3Ctitle%3E9BA61E07-5269-43B7-941A-11C5DFF67D9A%3C/title%3E%3Cdesc%3ECreated with sketchtool.%3C/desc%3E%3Cg id='Icons' stroke='none' stroke-width='1' opacity='0.5' fill='none' fill-rule='evenodd'%3E%3Cg transform='translate(-186.000000, -61.000000)' fill='%230E0E0E' id='icon/list/down'%3E%3Cg transform='translate(183.000000, 55.000000)'%3E%3Cpolygon id='Color' points='14.9901282 6.89393398 15.2022602 6.89393398 15.909367 7.60104076 15.909367 7.8131728 10.009872 13.7126677 9.79342899 13.7126677 3.89393398 7.8131728 3.89393398 7.60104076 4.60104077 6.89393398 4.81317281 6.89393398 9.9016505 11.9824116'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: right top;
        background-color: #fff;
        border: 0;
        border-radius: 0;
        padding-right: 20px;

        &:focus {
            outline: 0;
        }
    }

    .product-inner {
        padding-left: 15px;
        padding-right: 15px;

        &.related {
            padding-left: 0;
            padding-right: 0;

            .bookmark {
                left: 19px;

                @media (max-width: 768px) {
                    left: 27px;
                }
            }
        }

        &.variants {
            margin-top: 24px;

            h2 {
                line-height: 20px;
                margin-bottom: 4px;
            }

            .option-unavailable {
                color: #F12525;
                font-weight: normal;
                float: right;
            }

            .selected-option {
                font-size: 15px;
                line-height: 20px;
                margin-bottom: 4px;
            }
        }
    }

    .related-title { 
        h2 {
           margin-left: 15px;
        }
    }

    .price {
        color: #0E0E0E;
    }

    .full-description {
        border-radius: 16px 16px 0 0;
        background-color: #F9F9F9;
        display: block;
        width: 375px;
        z-index: 3;
        position: fixed;
        left: 0;
        max-height: 550px;
        height: 5500px;
        transition-property: all;
        transition-duration: .28s;
        transition-timing-function: ease-in-out;
        overflow-y: scroll;
        top: 130%;
        left: 50%;
        margin-left: -188px;

        &.visible {
            top: calc(100% - 450px);
        }

        &.thankyou.visible {
            max-height: 450px;
        }
    }

    .description {
        h2 {
            margin-bottom: 4.5px;
        }
    }

    .option {
        padding: 9px 0 7px 0;
        height: 56.5px;
        position: relative;

        &.shipping {
            color: #42B425;
            padding-top: 0;
            padding-bottom: 0;
            height: 45.5px;

            span {
                display: inline-block;
                line-height: 20px;
            }
        }

        &.shipping:after {
            width: 100%;
            content: '';
            position: absolute;
            left: 0;
            top: calc(100% - 0.5px);
        }

        p.selected {
            cursor: pointer;

            &:after {
                content: '';
                width: 13px;
                height: 8px;
                float: right;
                margin-top: 8px;
                background-image: url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' encoding='UTF-8'?%3E%3Csvg width='13px' height='8px' viewBox='0 0 13 8' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: sketchtool 54.1 (76490) - https://sketchapp.com --%3E%3Ctitle%3E9BA61E07-5269-43B7-941A-11C5DFF67D9A%3C/title%3E%3Cdesc%3ECreated with sketchtool.%3C/desc%3E%3Cg id='Icons' stroke='none' stroke-width='1' fill='none' opacity='0.5' fill-rule='evenodd'%3E%3Cg transform='translate(-186.000000, -61.000000)' fill='%230E0E0E' id='icon/list/down'%3E%3Cg transform='translate(183.000000, 55.000000)'%3E%3Cpolygon id='Color' points='14.9901282 6.89393398 15.2022602 6.89393398 15.909367 7.60104076 15.909367 7.8131728 10.009872 13.7126677 9.79342899 13.7126677 3.89393398 7.8131728 3.89393398 7.60104076 4.60104077 6.89393398 4.81317281 6.89393398 9.9016505 11.9824116'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
            }
        }

        &.width50 {
            width: calc(50% - 7.5px);
            float: left;
            border-top: 0;
            border-bottom: 0.5px solid rgba(14, 14, 14, 0.1);
            padding-bottom: 4px;
            height: 55px;

            p {
                margin: 0;
            }
        }

        &.width50+.width50 {
            float: right;
        }

        &.description {
            border-top: 0;
            border-bottom: 0;
            position: relative;
            padding-top: 1px;
            padding-bottom: 11px;
            height: 35px;
            border-bottom: 0.5px solid rgba(14, 14, 14, 0.1);

            &:after {
                width: 100%;
                border-top: 0.5px solid rgba(14, 14, 14, 0.1);
                //   content: '';
                position: absolute;
                left: 0;
                top: calc(100% - 1.5px);
            }

            p.selected {
                margin: 0;
            }
        }

        &.payments {
            border-bottom: 0;
            border-top: 0;
            padding-top: 10px;
            height: 40px;

            p.selected {
                margin: 0;
            }

            .full-description {
                background-color: #fff;

                &.visible {
                    top: calc(100% - 450px);
                }
            }
        }
    }

    .thankyou {
        z-index: 5;

        .padding {
            padding-left: 20px;
            padding-right: 20px;
        }

        .hr {
            background-color: rgba(14, 14, 14, 0.1);
            width: 100%;
            height: 1px;
            position: fixed;
        }

        .hr+p {
            color: #0E0E0E;
            padding: 16.5px 20px;
            font-size: 14px;
            line-height: 17px;
        }

        &.option-full {
            .center {
                height: 62px;
            }

            .desc {
                padding-top: 0px;
            }

            .close-option {
                top: 21px;
                font-size: 17px;
                line-height: 24px;
            }

            &.full-description {
                &.visible {
                    top: calc(100% - 450px);
                }
            }

        }


    }


    .option-full {
        z-index: 5;


        .center {
            text-align: center;
            padding-top: 22px;
            font-size: 18px;
            font-weight: 600;
            line-height: 24px;
            padding-bottom: 16px;
            margin-bottom: 0px;
        }

        .desc {
            overflow-y: scroll;
            height: 650px;
            padding-top: 19px;
        }

        .padding {
            padding-left: 20px;
            padding-right: 20px;
        }

        .hr {
            background-color: rgba(14, 14, 14, 0.1);
            width: 100%;
            height: 0.5px;
        }

        .hr+p {
            color: #0E0E0E;
            padding: 16.5px 20px;
            font-size: 14px;
            line-height: 17px;
        }
    }

    .payform {
        fieldset {
            padding: 0 15px;

            label {
                span {
                    padding-left: 0;
                }
            }
        }

        .field {
            padding-left: 0;
        }

        button {
            position: absolute;
            top: 7px;
            height: 24px;
            width: auto;
            color: #20B4E4;
            font-size: 17px;
            line-height: 24px;
            text-align: right;
            right: 0;
            background: transparent;
            border: 0;
            font-weight: normal;
            box-shadow: none;
            padding-right: 0;

            &:disabled {
                opacity: 0.5;
            }

            &:hover {
                transform: none;
            }

            &:focus,
            &:active {
                outline: 0;
            }


        }
    }


    .pay-container {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 4;
    }

    .pay {
        background-color: #FAFAFA;
        box-shadow: inset 0 1px 0 0 rgba(14, 14, 14, 0.1);
        color: #0E0E0E;
        padding: 15px 15px 49px 15px;
        margin: 0 auto;
        width: 375px;

        .prices {
            float: left;
            width: 99px;
        }

        .price {
            font-size: 24px;
            font-weight: 600;
            line-height: 44px;
            display: inline-block;
            margin-right: 8px;
        }

        .stroke {
            color: rgba(14, 14, 14, 0.3);
            text-decoration: line-through rgba(14, 14, 14, 0.3);
        }

        button.buynow {
            width: 200px;
            height: 48px;
            float: right;
            margin-top: 0;
            font-size: 20px;
        }

        button:not(.buynow) {
            color: #fff;
            background: #000000;
            //width: 246px;
            width: 200px;
            height: 48px;
            float: right;
            font-size: 20px;
            font-weight: 600;
            line-height: 24px;
            position: relative;
            border: 0;
            outline: 0;


            &:hover {
                opacity: .8;
            }

            &:active {
                top: 1px;
            }

            &:disabled {
                opacity: .3;
            }
        }
    }

    input[type="number"] {
        -moz-appearance: textfield;
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .products:not(.thankyou-products):not(.page404-products) {
        overflow-x: scroll;
        // width: 360px;
        margin-top: 16px;
        // margin-bottom: 40px;


        .inproducts {
            width: 2750px;
        }

        .product {
            float: left;
            width: 128px;
            min-height: 128px;
            padding-left: 0;
            margin-left: 15px;

            .discount {
                top: 0;
                line-height: 14px;
                width: auto;
            }

            .img {
                width: 128px;
                height: 128px;
            }

        }
    }
}

.share {
    float: right;
    border: 0;
    background: transparent;
    margin-right: -1px;
    margin-top: -4px;

    &:active, &:focus {
        outline: 0;
    }
}

.desc-full  {
    display: none;
}

.desc-short {
    font-size: 15px;
    line-height: 20px;
    color: #0E0E0E;
}

.readmore {
    color: rgba(14, 14, 14, 0.3);
    cursor: pointer;
    margin-top: 3px;
}

.variantsContainer {
    width: 100%;
    overflow-x: scroll;
    height: 95px;

    .variantsImages {

        .variant {
            margin-left: 10px;
          //  background:#f0f0f0;
            
            &:not(.unavailable){
                cursor: pointer;
            }
            
            &.unavailable {
                .overlayThumbnail + img,
                .overlayThumbnail > img {
                    opacity: 0.15
                }
            }

            &:first-child {
                margin-left: 15px;
            }

            width: 85px;
            height: 85px;
            float: left;
            position: relative;
            overflow: hidden;
            border-radius: 3px;
            
            &.selected {
                overflow: hidden;
                border-radius: 3px;
                width: 85px;
                height: 85px;

                >img {
                    display: none;
                }

                .overlayThumbnail {
                    display: block;

                    &.noPhoto {
                        height: 100%;
                        cursor: default;

                        &:after {
                            background: none;
                        }
                    }
                }
            }

            &:not(.selected) {

                img {
                    display: block;
                    border-radius: 3px;
                }

                button.overlayThumbnail {
                    display: none;
                }

            }

            >img {
                width: 85px;
                height: 85px;
                border-radius: 3px;
                object-fit: cover;
            }

        }
    }
}


.react-fancybox {
    .box {
        z-index: 6 !important;
        background: rgba(0,0,0,0.8) !important;

        .image-box {
            max-width: calc(100% - 30px);
            padding: 0 !important;
            background-color: transparent !important;

            &:before {
                border: 1px solid hsla(0,0%,100%,.8);
                border-radius: 73px;
                content: "Close";
                background-color: transparent;
                color:hsla(0,0%,100%,.8);
                width: 64px;
                height: 32px;
                font-size: 15px;
                line-height: 20px;
                font-weight: 400;
                display: block;
                text-align: center;
                padding: 4px 0;
                position: absolute;
                right: 0;
                top: -45px;
            }

            img {
                width: 100%;
                border-radius: 12px;

                &.close-btn {
                    display: none !important;
                }
            }
        }
    }


    .thumbnail {
        width: 100% !important;

        img {
            width: 100% !important;
        }
    }
}